<div class="flex align-items-center justify-content-between p-3 bg-gray-200">
  <div class="font-bold text-lg"></div>
  <div class="flex align-items-center">
    <div class="mr-4">
      <a href="#" class="text-gray-500 no-underline">Nosotros</a>
    </div>
    
    <div class="mr-4">
      <i class="pi pi-phone mr-2"></i>
      <a href="#" class="text-gray-500 no-underline">Contáctanos</a>
    </div>
    
    <div class="mr-4">
      <i class="pi pi-globe mr-2"></i>
      <span class="text-gray-500 no-underline">Español</span>
    </div>
  </div>
</div>
