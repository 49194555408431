import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { PRIME_NG_LANG } from './utils/lang.util';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ribera-frontend-web-app';
  private primengConfig = inject(PrimeNGConfig);
  
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(PRIME_NG_LANG);

  }
}
