import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  loaderService = inject(LoaderService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this.showLoader();
    
    return next.handle(request).pipe(
      catchError((error) => {
        return throwError(() => error); 
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.hideLoader();
        }
      })
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
