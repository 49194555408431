<div class="overlay" *ngIf="isLoading"></div>
<p-progressSpinner *ngIf="isLoading" ariaLabel="loading" class="loading-spinner"></p-progressSpinner>
<!-- <footer class="bg-image bg-cover bg-center w-full xl:px-32 2xl:px-52 pt-14 lg:pt-20"> -->
<footer class="bg-image bg-cover bg-center w-full xl:px-32 2xl:px-52 pt-14 lg:pt-20">

  <!--Content and links-->
  <div class="footer-columns">
    <!--Social Media -->
    <div class="footer-logo">
      <img src="/assets/images/svg/logo-ribera-menu.svg" class="w-10rem" alt="Go to home" />
      <ul class="flex flex-row align-items-center justify-content-center gap-3 none-list">
        <li>
          <a href="https://www.facebook.com/RiberaDelRioClubResort/?locale=es_LA" target="_blank">
            <img src="/assets/images/svg/icon-facebook.svg" alt="Facebook" style="height: 20px; width: 20px" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/riberadelrioclubresort6" target="_blank">
            <img src="/assets/images/svg/icon-instagram.svg" alt="Instagram" style="height: 20px; width: 20px" />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@clubriberadelrio" target="_blank">
            <img src="/assets/images/png/icon-tiktok.png" alt="Tiktok" style="height: 30px; width: 30px" />
          </a>
        </li>
      </ul>
    </div>

    <div class="footer-column">
      <h5 class="m-0 text-white font-bold text-base">
        RIBERA DEL RÍO
      </h5>
      <div class="flex flex-column gap-1">
        <a (click)="onClick('/about')" class="m-0 text-white font-normal text-sm">Nosotros</a>
        <a (click)="onClick('/restaurant')" class="m-0 text-white font-normal text-sm">Restaurante</a>
        <a (click)="onClick('/experience')" class="m-0 text-white font-normal text-sm">Experiencias</a>
        <a (click)="onClick('/accommodation/rooms')" class="m-0 text-white font-normal text-sm">Habitaciones</a>
        <a (click)="onClick('/contact')" class="m-0 text-white font-normal text-sm">Contacto</a>
      </div>
    </div>
    <!--Contact Us-->
    <div class="footer-column">
      <h5 class="m-0 text-white font-bold text-base">CONTACTO</h5>
      <div class="flex flex-column gap-1">
        <span class="m-0 text-white font-bold text-sm">Teléfonos:</span>
        <span class="m-0 text-white font-normal text-sm">+51 938 627 411</span>
      </div>
      <div class="flex flex-column gap-1">
        <span class="m-0 text-white font-bold pt-1 text-sm">Correo:</span>
        <span class="m-0 text-white font-normal text-sm break-all">
          informesyreservas&#64;cieneguillariberadelrio.com
        </span>
      </div>
    </div>

    <!--Ubicacion -->
    <div class="footer-column w-full">
      <h5 class="m-0 text-white font-bold text-base">UBICACIÓN</h5>
      <div class="flex flex-column gap-1">
        <p class="m-0 text-white font-bold text-sm">Nos encontramos en:</p>
        <div class="flex gap-1">
          <img src="/assets/images/svg/icon-ubication.svg" alt="Ubicación" />
          <span class="m-0 text-white font-normal text-sm mt-1">
            Km 29.5 Carretera Lima - Cieneguilla, Peru
          </span>
        </div>
      </div>
      <div class="footer-claim w-full ">
        <p class="m-0  font-bold text-sm  md:pt-0 sm:pt-6">Libro de reclamaciones</p>
        <a class="custom-claim-book" (click)="goToClaimBook()">
          <img src="/assets/images/svg/icon-claim-book.svg" alt="Claim book" class="w-24" />
        </a>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
  <div class="footer-container">
    <div class="border"></div>
    <div class="text-container">
      <a (click)="onClick('/terms-and-conditions')" class="footer-link">Términos y condiciones</a>
      <span class="mx-3 hidden sm:block">|</span>
      <a (click)="onClick('/personal-data-use-policy')" class="footer-link">Política de Uso de Datos</a>
      <span class="mx-3 hidden sm:block">|</span>
      <a (click)="onClick('/acceptable-use-policy')" class="footer-link">Política de Uso Aceptable</a>
      <!-- <span class="text-white text-sm">Términos y condiciones</span>
      <span class="text-white text-sm">Política de Uso de Datos</span>
      <span class="text-white text-sm">Política de Uso Aceptable</span> -->
    </div>
    <div class="border"></div>
  </div>

  <div class="whatsapp-float">
    <app-whatsapp-float></app-whatsapp-float>
  </div>



  <span class="text-xs text-center font-normal w-full mt-4 pb-4 xl:mt-20 block" style="color: #d2cece">
    © 2024 Rivera del Río Todos los Derechos Reservados
  </span>
</footer>