<section
  class="whatsapp-float fixed flex align-items-center justify-content-center bottom-4 right-1 md:bottom-5 md:right-5 lg:bottom-4"
>
  <a
    href="https://api.whatsapp.com/send?phone=51938627411&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20de%20los%20servicios%20del%20hotel%20Ribera%20del%20Rio."
    target="_blank"
    class="bg-green-500 border-circle flex align-items-center justify-content-center cursor-pointer w-3rem h-3rem md:w-3.5rem md:h-3.5rem no-underline"
  >
    <i class="pi pi-whatsapp text-2xl md:text-3xl text-white"></i>
  </a>
</section>
