import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';


if (environment.production) {
  enableProdMode();
}

if (!environment.debug) {
  console.log( '%cSi estás leyendo este mensaje es que eres demasiado inteligente,  BA!', 'color: #bada55; font-weight: bold; font-size: 16px' );

  window.console.log = () => {
  };
  window.console.info = () => {
  };
  window.console.error = () => {
  };
  window.console.table = () => {
  };
  window.console.warn = () => {
  };
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
