import { ApplicationConfig } from '@angular/core';
import { provideRouter, RouterModule } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { ScrollService } from './services/scroll.service';

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    DialogService,
    ScrollService,
    PrimeNGConfig,
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideOAuthClient(),
    provideHttpClient(withFetch()),
    DatePipe,
  ],
};
