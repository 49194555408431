import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthPromotorGuard implements CanActivate {


  constructor(private router: Router, private generalService: GeneralService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      const token = this.generalService.getKey('tokenP')
      // const token = localStorage.getItem('token')
    if (token) {
      return true;
    }
    // return this.router.navigateByUrl('/login').finally();

    return this.router.createUrlTree(['/promotor/login']);
  }
}
