import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';
import { WhatsappFloatComponent } from "../../shared/whatsapp-float/whatsapp-float.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule, WhatsappFloatComponent],
  templateUrl: './footer-promotor.component.html',
  styleUrl: './footer-promotor.component.scss'
})
export class FooterComponent {

  public isLoading: boolean = false;

  constructor(private router: Router) { }

  goToClaimBook() {
    this.isLoading = true;
    this.router.navigate(['/claim-book']).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

  onClick(url: string) {
    this.router.navigate([url]).then(() => {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

}
