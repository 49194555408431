import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar-promotor.component';
import { FooterComponent } from './footer/footer-promotor.component';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../core/components/loading/loading.component';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterModule,
    NavbarComponent,
    FooterComponent,
    LoadingComponent,
    CommonModule,
  ],
  templateUrl: './layout-promotor.component.html',
  styleUrl: './layout-promotor.component.scss'
})
export class LayoutPromotorComponent implements OnInit {
  loaderService = inject(LoaderService);
  loading: boolean = false;
  ngOnInit(): void {
    this.loaderService.loading$.subscribe((value) => {
      this.loading = value ?? false;
    });
  }
}